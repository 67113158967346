import React from 'react'
import ListCreatePost from "./ListCreatePost"
import ListCommunityPost from './ListCommunityPost';
import ListProductSlider from "./ListProductSlider";
import ListJob from "./ListJob";
import ListService from "./ListService"
export default function ListPostMain() {
  return (
    <>
      <ListCreatePost/>
      <ListCommunityPost/>
      <ListProductSlider/>
      <ListService/>
      <ListJob/>
    </>
  )
}
