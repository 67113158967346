import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { BiSearchAlt } from "react-icons/bi";
import { FaFilter } from "react-icons/fa";

import { HiOutlineBellAlert, HiOutlineUserCircle } from "react-icons/hi2";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";
export default function SearchBar() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isNotificationOpen, setisNotificationOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const togglenotification = () => {
    setisNotificationOpen(!isNotificationOpen);
  };

  return (
    <>


    
      <div className="flex flex-row justify-center items-center xs:hidden ">
        <form className="w-[50%]  mt-4 mb-4">
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only :text-white"
          >
            Search
          </label>
          <div className=" relative left-[6rem]">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 :text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="search"
              onFocus={toggleDropdown}
              id="default-search"
              className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 :bg-gray-700 :border-gray-600 :placeholder-gray-400 :text-white :focus:ring-blue-500 :focus:border-blue-500"
              placeholder="i am looking for ..."
              required=""
            />
            <button
              onClick={toggleDropdown}
              className="flex flex-row items-center gap-2 text-white font-all absolute right-2.5 bottom-2.5 bg-[#cb2229] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 :bg-blue-600 :hover:bg-blue-700 :focus:ring-blue-800"
            >
              Filter <FaFilter />
            </button>
          </div>

          {isDropdownOpen && (
            <div className="shadow-lg drop-shadow-md border border-black  absolute mt-2 w-[50rem] bg-white rounded-md drop-filter lg:left-[425px] lg:top-[4rem] scale-in-ver-top z-10	">
              <div className="py-1">
                {/* // */}
                {/* text  */}
                <div className="flex flex-row items-center lg:gap-[35rem] bg-[#5c5a5a52] p-[10px]">
                  <h1 className="font-all font-semibold text-[20px] font-ld ml-4 text-[#303432] ">
                    Recent Searches{" "}
                  </h1>
                  <RxCross1
                    className="lg:text-[20px] cursor-pointer"
                    onClick={toggleDropdown}
                  />
                </div>

                <div className="flex flex-col mt-[12px]">
                  <div className="flex flex-row items-center border border-y py-[10px] border-gray-200 gap-4">
                    <BiSearchAlt className="text-[23px] ml-[4rem]" />{" "}
                    <h6 className="font-all font-semibold text-[18px] relative left-4">
                      Mobile Accesories
                    </h6>{" "}
                    <AiOutlineRight className="text-[22px] relative left-[17rem]" />
                  </div>
                  <div className="flex flex-row items-center border border-y py-[10px] border-gray-200 gap-4">
                    <BiSearchAlt className="text-[23px] ml-[4rem]" />{" "}
                    <h6 className="font-all font-semibold text-[18px] relative left-4">
                      Mobile Accesories
                    </h6>{" "}
                    <AiOutlineRight className="text-[22px] relative left-[17rem] " />
                  </div>
                  <div className="flex flex-row items-center border border-y py-[10px] border-gray-200 gap-4">
                    <BiSearchAlt className="text-[23px] ml-[4rem]" />{" "}
                    <h6 className="font-all font-semibold text-[18px] relative left-4">
                      Home decoration
                    </h6>{" "}
                    <AiOutlineRight className="text-[22px] relative left-[17rem]" />
                  </div>
                </div>

                {/* <hr className="relative lg:top-[1rem]" /> */}
                {/* // */}
                {/* text  */}
                <div className="flex flex-row items-center lg:gap-[35rem] bg-[#5c5a5a52] p-[10px]">
                  <h1 className="font-all font-semibold text-[20px] font-ld ml-4 text-[#303432] ">
                    Best selling{" "}
                  </h1>
                </div>
                <hr />

                <div className="flex flex-col mt-[12px]">
                  <div className="flex flex-row items-center border border-y py-[10px] border-gray-200 gap-4">
                    <BiSearchAlt className="text-[23px] ml-[4rem]" />{" "}
                    <h6 className="font-all font-semibold text-[18px] relative left-4">
                      Mobile Accesories
                    </h6>{" "}
                    <AiOutlineRight className="text-[22px] relative left-[17rem]" />
                  </div>
                  <div className="flex flex-row items-center border border-y py-[10px] border-gray-200 gap-4">
                    <BiSearchAlt className="text-[23px] ml-[4rem]" />{" "}
                    <h6 className="font-all font-semibold text-[18px] relative left-4">
                      Mobile Accesories
                    </h6>{" "}
                    <AiOutlineRight className="text-[22px] relative left-[17rem] " />
                  </div>
                  <div className="flex flex-row items-center border border-y py-[10px] border-gray-200 gap-4">
                    <BiSearchAlt className="text-[23px] ml-[4rem]" />{" "}
                    <h6 className="font-all font-semibold text-[18px] relative left-4">
                      Home decoration
                    </h6>{" "}
                    <AiOutlineRight className="text-[22px] relative left-[17rem]" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </form>

        <div className="flex flex-row items-center relative left-[11rem] gap-[13px]">
          <HiOutlineBellAlert
            onClick={togglenotification}
            className="text-[#6f6868] text-[24px] cursor-pointer"
          />
          {isNotificationOpen && (
            <div className="border-[1px] border-black shadow-md drop-shadow-md absolute mt-2 w-[17rem] bg-white rounded-md drop-filter right-0 lg:top-[1rem] scale-in-ver-top z-10	">
              <div className="py-1">
                {/* // */}
                {/* text  */}
                <div className="flex flex-row items-center lg:gap-[6rem] lg:mt-[6px]">
                  <h1 className="font-all font-semibold text-[20px] ml-4 text-[#3b3e3c] ">
                    Notifications{" "}
                  </h1>
                  <RxCross1
                    className="lg:text-[20px] cursor-pointer"
                    onClick={togglenotification}
                  />
                </div>
                <hr className="mt-1" />
                <div className="flex flex-col  justify-start">
                  <div className=" bg-[#f7dedf] ml-2 flex flex-row justify-start items-center gap-[21px] mt-4">
                    <div className="image-grid lg:w-[44px]"></div>
                    <span className="font-semibold">
                      1 upcomming event :{" "}
                      <span className="mont-serif lg:text-[12px]">
                        {" "}
                        Lorem ipsum dolor sit amet consectetur adipisicing.
                      </span>{" "}
                    </span>
                  </div>
                  {/* -- b */}

                  <div className="  bg-[#f7dedf] ml-2 flex flex-row justify-start items-center gap-[21px] mt-2 border-y -black p-[3px]">
                    <div className="image-grid lg:w-[44px]"></div>
                    <span className="font-semibold">
                      1 upcomming event :{" "}
                      <span className="mont-serif lg:text-[12px]">
                        {" "}
                        Lorem ipsum dolor sit amet consectetur adipisicing.
                      </span>{" "}
                    </span>
                  </div>

                  <div className=" ml-2 flex flex-row justify-start items-center gap-[21px] mt-2 border-y -black p-[3px]">
                    <div className="image-grid lg:w-[44px]"></div>
                    <span className="font-semibold">
                      1 upcomming event :{" "}
                      <span className="mont-serif lg:text-[12px]">
                        {" "}
                        Lorem ipsum dolor sit amet consectetur adipisicing.
                      </span>{" "}
                    </span>
                  </div>

                  <div className=" bg-[#f7dedf]  ml-2 flex flex-row justify-start items-center gap-[21px] mt-2 border-y -black p-[3px]">
                    <div className="image-grid lg:w-[44px]"></div>
                    <span className="font-semibold">
                      1 upcomming event :{" "}
                      <span className="mont-serif lg:text-[12px]">
                        {" "}
                        Lorem ipsum dolor sit amet consectetur adipisicing.
                      </span>{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          <Link to="/userProfile">
            <HiOutlineUserCircle className="text-[#404040] text-[28px]" />
          </Link>
        </div>
      </div>
    </>
  );
}
