import React from 'react'
import JobCreatePost from './JobCreatePost'
import JobCommunityPost from './JobCommunityPost'
import JobProductSlider from './JobProductSlider'
import JobServicePost from './JobServicePost'
import FindJobPost from './FindJobPost'

export default function JobPostMain() {
  return (
    <>
     <JobCreatePost/>
     <JobCommunityPost/>
     <JobProductSlider/>
     <JobServicePost/>
     <FindJobPost/> 
    </>
  )
}
