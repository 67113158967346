import React from 'react'

export default function ListCreatePost() {
  return (
    <>
           <div className="mb-8  w-[93%] mx-auto border-[1px] border-[#c2c1c1] rounded-md  relative top-6">
    <h1 className="text-center mt-1 mb-3 font-all font-semibold text-[19px] ">
        Create Post
    </h1>
    <div className="avatar-detail">

        <div className="flex flex-row ml-5 gap-3">
        <img class="w-10 h-10 rounded-full" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YXZhdGFyfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60" alt="Rounded avatar"/>
<div className="flex flex-col">
    <h5>stephen jhone</h5>

<div className="flex flex-row items-center lg:gap-[41rem]">

    <div className="flex flex-row gap-4 mt-2 mb-3">
    <div className="button shadow-md border-[1px] w-[78px] text-center rounded-md transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white cursor-pointer">public</div>
    <div className="button shadow-md border-[1px] w-[78px] text-center rounded-md transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white cursor-pointer">album </div>
    <div className="button shadow-md border-[1px] w-[78px] text-center rounded-md transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white cursor-pointer">off</div>
    </div>


    <div className="flex flex-row justify-end">
        <button className='p-1 button shadow-md border-[1px] w-[78px] text-center rounded-full transform hover:scale-105 duration-500 ease-in-out bg-[#cb222897]  hover:bg-[#CB2229] hover:text-white cursor-pointer text-white'> Post </button>
    </div>

    </div>
</div>


        </div>
    </div>
    <div class="mb-6 mt-4">
 
    <input type="text" id="large-input" class="block mx-auto w-[95%] text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder='write a post'/>
</div>
   </div> 
    </>
  )
}
