import React from 'react'
import coin from "../avatarimg/coin.png"
import { NavLink } from 'react-router-dom'

function AvatarWallet() {
  return (
    <>
         <div className="flex flex-col mt-[27px]">
        <img src={coin} className="h-[18rem] w-[276px] image-coin" alt="" />

        <div class="button-action flex flex-col items-center mt-[4rem] ">

        <NavLink to='/wallet/withdrawlsuccess'>
          <button class=" bg-[#CB2229] text-white rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative">
            Proceed
          </button></NavLink>
          

          
          <NavLink to="/wallet">
          <button class="mb-6 border-[1px] border-[#CB2229] text-black rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative  ">
            Go Back
          </button>
          </NavLink>
        </div>
      </div> 
    </>
  )
}

export default AvatarWallet
