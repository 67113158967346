import { useState, useEffect } from "react";




function ReferOtp() {
  const [otp, setOtp] = useState(["", "", "", ""]);

  const handleChange = (e, index) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);
  };

  return (
    <>

<div className="fade-in-right flex flex-col items-center border-[1px] border-[#D2D2D2]  rounded-md lg:p-[28px] lg:mt-[4rem] lg:mb-[7rem]">
        <h1 className=" font-all font-extrabold text-[23px] text-color lg:relative mb-4">
        Use your Code
        </h1>
<h6 className="font-all text-[black]">recieve a verify code enter it below</h6>

        <div   className="flex justify-center items-center lg:mt-[4rem] lg:mb-16">
      {otp.map((digit, index) => (
        <input
          key={index}
          type="text"
            className="text-black w-16 h-16 mx-4 text-4xl text-center border border-gray-300   bg-transparent rounded-lg focus:outline-none focus:border-indigo-500"
          maxLength={1}
          value={digit}
          onChange={(e) => handleChange(e, index)}
        />
      ))}
    </div>



{/* <hr className="border-[1px] border-dashed border-[#D2D2D2] " />/ */}
<hr className="border-[1.5px] border-dashed w-[100%] border-[#D2D2D2] " />
    <button class=" hover:bg-[#CB2229]   bg-[#CB2229]  border-[1px] border-[#CB2229]  text-white hover:text-white rounded-full font-all p-[6px] lg:mt-[5rem] w-[20rem]   transform hover:scale-105 duration-500 ease-in-out relative  ">
            Enter Code 
          </button>
      </div>
    </>
  )
}

export default ReferOtp
