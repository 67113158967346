import React from 'react'
import Logo from "../../../../assets/logo/Logo-Trans.png"
import image from "../../../../assets/logo/auth.png"
import { Link } from 'react-router-dom'
// import ResellerBUtton from '../../../../component/ResellerBUtton'


const ResellerVerifyPhone = () => {

  return (
    <>
       <div className="">
        <div className="flex flex-row justify-start  ">
          <div className="item-1 h-screen lg:w-[100vh] bg-[#cb22290f]">
            <div className="flex flex-col justify-center items-center mt-12 gap-[12pxs]">
              <img
                src={Logo}
                alt=""
                className="lg:w-[134pxl.] bounce-in-top  "
              />
            </div>
            <div className="text">
              <h1 className="text-center font-bold text-[35px] leading-[70px] text-[#738482] mt-9">
                Welcome to INO
              </h1>
              <p className="text-center  text-[#738482] font-all font-semibold ">
                Register now to gain access to your account{" "}
              </p>
            </div>
            <div className="image relative top-[14px]">
              <img
                src={image}
                className="mx-ato m-auto h-[21rem] image-coin"
                alt=""
              />
            </div>

            <div className="flex flex-row items-center gap-4 justify-center mt-[7rem]">
              <p className="text-[14px]">License Policy</p>
              <p className="border-x p-[8px]  text-[14px] border-black">
                Privacy Policy
              </p>
              <p className="text-[14px]">Terms and Conition</p>
            </div>
            <p className="text-[#738482] text-[13px] text-center">
              Copyrights © 2023 All Rights Reserved by{" "}
              <span className="text-[#CB2229]">INO</span>{" "}
            </p>
          </div>
          <div className="item-2 bg-[#cb22290f] w-[50%] flex ">
            <div className="bg-white shdaow-md w-[88%] rounded-md  mt-[9rem] h-[552px] drop-c fade-in-right ">
              <h1 className="text-all text-center font-bold text-[22px]  relative top-[26px]">
                Verify Phone Number
              </h1>
              <h3 className="text-center text-[#000000] lg:mt-[36px]">
                Waiting to automatic detect an SMS sent to +91 81981 77072.
              </h3>
              <p className="text-[#8C8C8C] text-center mt-[14px]">
                Wrong number?
              </p>

              <div className="input-flex-row flex flex-row gap-[22px] justify-center mt-[13px]">
                <input
                  className="w-[40px] focus:border-none mont-serif appearance-none  py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                  type="text"
                  name="field1"
                  id="field1"
                  style={{ border: "none", borderBottom: "1px solid" }}
                />

                <input
                  className="w-[40px] focus:border-none mont-serif appearance-none  py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                  type="text"
                  name="field1"
                  id="field1"
                  style={{ border: "none", borderBottom: "1px solid" }}
                />

                <input
                  className="w-[40px] focus:border-none mont-serif appearance-none  py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                  type="text"
                  name="field1"
                  id="field1"
                  style={{ border: "none", borderBottom: "1px solid" }}
                />

                <input
                  className="w-[40px] focus:border-none mont-serif appearance-none  py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                  type="text"
                  name="field1"
                  id="field1"
                  style={{ border: "none", borderBottom: "1px solid" }}
                />

                <input
                  className="w-[40px] focus:border-none mont-serif appearance-none  py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                  type="text"
                  name="field1"
                  id="field1"
                  style={{ border: "none", borderBottom: "1px solid" }}
                />

                <input
                  className="w-[40px] focus:border-none mont-serif appearance-none  py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                  type="text"
                  name="field1"
                  id="field1"
                  style={{ border: "none", borderBottom: "1px solid" }}
                />
              </div>
              <hr className="border border-[#738482] mt-[4.25rem] w-[50%] mx-auto m-auto " />

              <p className="text-[#8C8C8C] text-center mt-[18px]">
                Enter 6 Digit code
              </p>

              <p className="text-center text-[12px] mt-[50px] underline  underline-offset-2">
                Didn’t receive code?
              </p>

              <div className="flex flex-col gap-3 mt-[40px]">
                <button class=" flex justify-center items-center mx-auto m-auto border-[1px] border-[#CB2229] text-black rounded-full font-all p-[2px]  w-[15rem]   transform hover:scale-105 duration-500 ease-in-out ">
                  send again in 0:04
                </button>

                <button class=" flex justify-center items-center mx-auto m-auto border-[1px] border-[#CB2229] text-black rounded-full font-all p-[2px]  w-[15rem]   transform hover:scale-105 duration-500 ease-in-out ">
                  Get code by email instead
                </button>
               
          <Link to="/" >
                <button   class=" flex justify-center items-center mx-auto m-auto border-[1px] bg-[#CB2229] text-white rounded-full font-all p-[2px]  w-[15rem]   transform hover:scale-105 duration-500 ease-in-out ">
                 next   
                  </button>
                  </Link>
              </div>
            </div>
          </div>
        </div>
      </div> 

    </>
  )
}

export default ResellerVerifyPhone;

