import React from 'react'
import {BsPencil} from "react-icons/bs"
export default function ViewCard() {
  return (
 <>
 <div className="bg-layout shadow-md drop-shadow-md lg:w-[155vh] mb-[7rem] lg:ml-[268px] relative top-[1rem] h-[90vh]">
<h1 className="fade-in-left font-all text-center font-semibold lg:text-[22px] lg:mt-[5rem] mb-8">
  Your bussiness Card 
</h1>
<div className="fade-in-right bg-white drop-c  w-[45%] mx-auto h-[23rem]  mt-8" >
  <h1 className="font-all text-left ml-4 font-semibold lg:text-[18px] relative top-[1rem] ">
    Full name :
  </h1>
  
<div className="flex flex-col justidy-start ml-4 gap-8 mt-[4rem]">
<p className="font-all">Occupation / Identification :</p>
<p className="font-all">Location :</p>
<p className="font-all">Email id :</p>
<p className="font-all">Mobile Number :</p>


</div>

<hr className="w-full border-grey-300 mt-4" />
<div className="flex flex-row items-center ml-4 lg:gap-[23rem] text-[14px] mt-4">
  <span>edit information </span> <BsPencil/>
</div>
<hr className="w-full mt-2 border-grey-300" />

</div>

<div className=" button-action flex flex-col items-center lg:mt-[2rem] dro-c lg:w-[45%] mx-auto">
    <button className=" bg-[#CB2229] text-white rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative">
  Share card
    </button>
    <button className=" mb-6 border-[1px] border-[#CB2229] text-black rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative  ">
  Go back
    </button>
  </div>

 </div>
 
 
 </>
  )
}
