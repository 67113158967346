import React from 'react'
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";

export default function JobProductSlider() {
  const carouselData = [
    {
      name: "Apple",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTko7wO6ZZQX-qxqf4xATkFaRkF5sVwZZC-K4Zn_f4NhrLPJr2Z1ToLsmwntHW2c3mF-UI&usqp=CAU",
    },
    {
      name: "samsung",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTko7wO6ZZQX-qxqf4xATkFaRkF5sVwZZC-K4Zn_f4NhrLPJr2Z1ToLsmwntHW2c3mF-UI&usqp=CAU",
    },
    {
      name: "lenovo",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTko7wO6ZZQX-qxqf4xATkFaRkF5sVwZZC-K4Zn_f4NhrLPJr2Z1ToLsmwntHW2c3mF-UI&usqp=CAU",
    },
    {
      name: "mi",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTko7wO6ZZQX-qxqf4xATkFaRkF5sVwZZC-K4Zn_f4NhrLPJr2Z1ToLsmwntHW2c3mF-UI&usqp=CAU",
    },
    {
      name: "poco",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTko7wO6ZZQX-qxqf4xATkFaRkF5sVwZZC-K4Zn_f4NhrLPJr2Z1ToLsmwntHW2c3mF-UI&usqp=CAU",
    },
    {
      name: "realme",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTko7wO6ZZQX-qxqf4xATkFaRkF5sVwZZC-K4Zn_f4NhrLPJr2Z1ToLsmwntHW2c3mF-UI&usqp=CAU",
    },
    {
      name: "realme",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTko7wO6ZZQX-qxqf4xATkFaRkF5sVwZZC-K4Zn_f4NhrLPJr2Z1ToLsmwntHW2c3mF-UI&usqp=CAU",
    },
    {
      name: "realme",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTko7wO6ZZQX-qxqf4xATkFaRkF5sVwZZC-K4Zn_f4NhrLPJr2Z1ToLsmwntHW2c3mF-UI&usqp=CAU",
    },
    {
      name: "realme",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTko7wO6ZZQX-qxqf4xATkFaRkF5sVwZZC-K4Zn_f4NhrLPJr2Z1ToLsmwntHW2c3mF-UI&usqp=CAU",
    },
    {
      name: "realme",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTko7wO6ZZQX-qxqf4xATkFaRkF5sVwZZC-K4Zn_f4NhrLPJr2Z1ToLsmwntHW2c3mF-UI&usqp=CAU",
    },
  ];
  const scrollLeft = () => {
    document.getElementById("content").scrollLeft -= 400;
  };
  const scrollRight = () => {
    document.getElementById("content").scrollLeft += 400;
  };
  return (
    <>
       <div
        class="flex items-center justify-center h-48 mt-10 rounded  lg:h-[24rem]"
        style={{ border: "1px solid #D2D2D2" }}
      >
        <div className="w-[140vh]">
          {/*  */}

          <div className="relative">
            <h1 className="text-center font-all font-extrabold text-[#263238] lg:text-[19px]">
              Products
            </h1>

            <div className="absolute right-0 lg:top-[3.2rem] z-10 xs:top-[20px]">
              <button
                onClick={scrollLeft}
                className="relative lg:top-[77px] bg-[#0000002e] lg:h-[3rem] p-2 m-2 rounded-md lg:right-[67.5rem] carousel-bg"
              >
                <FiChevronLeft className="relative lg:right-[1px] text-black" />
              </button>
              <button
                onClick={scrollRight}
                className=" relative lg:top-[77px] bg-[#0000002e] lg:h-[3rem] p-2 m-2 rounded-md lg:left-[65px]"
              >
                <FiChevronRight className="relative text-black" />
              </button>
            </div>
            <div
              id="content"
              className="carousel p-4 flex items-center justify-start overflow-x-auto scroll-smooth scrollbar-hide lg:h-[auto] -m-4 mt-1"
            >
              {carouselData.map((item) => (
                <div className="carousel-card lg:ml-[-18px]" key={item.name}>
                  <div className="p-4 lg:w-[246px] xs:w-[14rem] drop-shadow-xl">
                    <div className="lg:h-[242px] lg:w-[214px]   shadow-inner pb-24 rounded-lg overflow-hidden text-center relative intrest-card bg-[#ffff] h-[137px]  ">
                      <img
                        src={item.image}
                        className="lg:h-[142px] l:w-[242px]"
                        alt=""
                      />
                      <h5 className="text-black font-all font-bold lg:mt-[9px]">
                        {item.name}
                      </h5>
                      <p className="font-all text-[#263238] lg:w-[190px] inline text-[13px] font-semibold">
                        Brief Description which leads to produt page
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>  
    </>
  )
}
