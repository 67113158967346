import React from 'react'
import { FaStar } from 'react-icons/fa';

import {GoVerified} from "react-icons/go"
function CommunityPost() {
    const movies = [
        {
          imageSrc: 'https://images.unsplash.com/photo-1516387938699-a93567ec168e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8bGFwdG9wfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60',
          altText: 'buyer name 2 1',
          title: 'Buyer name 1',
          description: 'Click the button to watch on Jetflix app.',
        },
        {
          imageSrc: 'https://media.istockphoto.com/id/1425235236/photo/side-view-of-youthful-african-american-schoolboy-working-in-front-of-laptop.webp?b=1&s=170667a&w=0&k=20&c=kFD3SJkeFa21orHCMEkF1RUcU5VHstLUKKBvx2Xfb4Q=',
          altText: 'Movie 2',
          title: 'Service Name 2',
          description: 'Click the button to watch on Jetflix app.',
        },
        {
            imageSrc: 'https://media.istockphoto.com/id/1483977253/photo/stone-carving-of-goddess-nike.webp?b=1&s=170667a&w=0&k=20&c=25gzmSDINqSg1aJ-ijxH5be095p-Z6reYmwcPkxanJk=',
            altText: 'buyer name 2 1',
            title: 'Buyer name 1',
            description: 'Click the button to watch on Jetflix app.',
          },
        // Add more movie objects as needed
      ];
  return (
    <>
          <div className="flex items-center justify-center mt-10 rounded w-[93%]
          mx-auto " style={{ border: '1px solid #D2D2D2' }}>
        <div className="flex flex-col gap-4 mb-8">
          <h1 className="font-all font-bold text-start -auto text-[21px] lg:mt-[27px] mb-6">All Community Posts</h1>
          {movies.map((movie, index) => (
            <div className="flex drop-shadow-md drop  h-[28vh] w-[140vh] rounded-lg" key={index}>
              <figure>
                <img className="w-[400px] rounded-l-lg  shadow-md h-[14rem]" src={movie.imageSrc} alt={movie.altText} />
              </figure>
              <div className="card-body">
                <h2 className="font-bold font-all lg:mt-[30px] lg:ml-12 lg:text-[17px]">{movie.title}</h2>

                <div className="flex flex-row items-center lg:ml-[2rem] lg:mt-[1rem] lg:gap-[15px]">
                  <div className="badge bg-[#E98F92] p-[5px] rounded-md">
                    <h4 className="font-semibold flex flex-row items-center text-[#263238]">
                      Verified <span><GoVerified /></span>
                    </h4>
                  </div>

                </div>

                <h5 className="font-all text-[#263238] lg:p-[24px] font-semibold">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint molestiae praesentium expedita quos quo dolorum id velit deleniti, reiciendis quaerat.
                </h5>

              </div>
            </div>
          ))}
            <h1 className=" mt-2 cursor-pointer underline-offset-4 text-center font-all    hover:text-blue-600 w-[12%] mx-auto">
            See more
        </h1>
        </div>
      
      </div>
    </>
  )
}

export default CommunityPost
