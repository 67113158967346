import React, { useState, useEffect } from "react";

import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { RxDotFilled } from "react-icons/rx";

function Homeslider() {
  const slides = [
    {
      url: "https://img.freepik.com/free-vector/stylish-black-friday-biggest-sale-poster-with-text-space_1017-41356.jpg?w=1380&t=st=1687384781~exp=1687385381~hmac=fa3098c8cad1003930c4a8655fe1604481819e5030499787bab117e1cc9bee8d",
    },
    {
      url: "https://img.freepik.com/free-psd/gadget-concept-landing-page-template_23-2148626924.jpg?w=1380&t=st=1687385009~exp=1687385609~hmac=2f7437e0d227be84275d96aa736185a8c3d5e0f7291ec7ad616253cc9e420980",
    },
    {
      url: "https://img.freepik.com/free-vector/stylish-black-friday-biggest-sale-poster-with-text-space_1017-41356.jpg?w=1380&t=st=1687384781~exp=1687385381~hmac=fa3098c8cad1003930c4a8655fe1604481819e5030499787bab117e1cc9bee8d",
    },
    {
      url: "https://img.freepik.com/free-vector/flat-black-friday-twitter-cover-template_23-2149103019.jpg?w=1380&t=st=1687385119~exp=1687385719~hmac=1208af85571ce707c595b0f507be8d4aa88c50a2146b62eea52a2404118be047",
    },
    {
      url: "https://img.freepik.com/free-vector/social-media-cover-template-cyber-monday_23-2149698956.jpg?w=1380&t=st=1687384912~exp=1687385512~hmac=8471b84eaac6fe912b0b9a07d0ce329a36a3aa3be2b4d73351a61490a13c7f0f",
    },
    {
      url: "https://img.freepik.com/free-psd/eid-al-adha-horizontal-banner-template-with-ram-crescent-moon_23-2149443668.jpg?w=1380&t=st=1687384927~exp=1687385527~hmac=7c90ef4d1b614210ec29dd393b82916df9b63b1a5fef2f080f1d7b680378ed6b",
    },
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  useEffect(() => {
    const timeout = setTimeout(nextSlide, 3300);
    return () => clearTimeout(timeout);
  }, [currentIndex]);

  return (
    <>
      <div className="max-w-[1520px] h-[362px] w-full m-auto  relative group">
        <div
          style={{ backgroundImage: `url(${slides[currentIndex].url})` }}
          className="w-full h-[362px] xs:h-[174px] rounded-2xl bg-center bg-cover duration-500"
        ></div>

        <div className=" absolute  xs:top-[24%] top-[48%] -translate-x-0 translate-y-[-50%] left-[22.75px] xs:left-[-27px] text-2xl rounded-md p-2 bg-black/20 xs:bg-[#20394b52] xs:p-[0px] xs:h-[2.3rem]  xs:flex xs:items-center   text-white cursor-pointer">
          <BsChevronCompactLeft onClick={prevSlide} size={20} />
        </div>
        {/* Right Arrow */}
        <div className=" absolute  xs:top-[24%] top-[48%] -translate-x-0 translate-y-[-50%] right-[22.75px] xs:right-[-26px] text-2xl rounded-md p-2 bg-black/20  xs:bg-[#20394b52] xs:p-[0px] xs:h-[2.3rem]  xs:flex xs:items-center  text-white cursor-pointer">
          <BsChevronCompactRight onClick={nextSlide} size={20} />
        </div>
        <div className="flex top-4 justify-center py-2">
          {slides.map((slide, slideIndex) => (
            <div
              key={slideIndex}
              onClick={() => goToSlide(slideIndex)}
              className="text-2xl cursor-pointer"
            >
              <RxDotFilled className="text-[#CB2229]" />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Homeslider;
