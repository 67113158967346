import React from 'react'
import { NavLink } from "react-router-dom";
import { HiArrowLongLeft } from "react-icons/hi2";
import { AiOutlineCheck } from "react-icons/ai";
import gif from "../avatarimg/coin.png";

function Withdrawtransfersuccess() {
  return (
    <>
           <NavLink to="/wallet/walletMoney ">
        <div
          className=" lg:relative lg:top-[78px]  lg:w-[18%]  lg:left-[17rem]    flex flex-row item-center justify-start gap-3 z-10"
          style={{ alignItems: "center" }}
        >
          <span className="text-[32px] text-[#4b5563]">
            <HiArrowLongLeft />
          </span>
          <h1 className="font-semibold  text-[#4b5563] text-[20px]">
            Go Back to Wallet
          </h1>
        </div>
      </NavLink>

      <div className="bg-layout drop-shadow-md lg:w-[152vh]  lg:ml-[17rem] relative top-[1rem] swing-in-top-fwd h-[72vh]">
        <div className="flex flex-row gap-[14.5rem]  mt-[4rem] justify-center">
          <div className=" mt-5 wallet-content h-[282px] w-[367px] rounded-md shadow-md drop-shadow-md border-[1px] border-[#989898] mb-4">
            <div className="bg-[#98989863] p-[1px] ">
              <h1 className="font-all text-center font-semibold">
                Funds Withdrawl Succesfully !
              </h1>
            </div>
            <div className="icon w-[13%] flex justify-center items-center drop m-auto mx-auto p-[13px] mt-[2rem]  rounded-full bounce-in-top">
              <AiOutlineCheck className="text-[23px]" />
            </div>
            {/* account added balance div below */}
            <div className="wallet-added-balance">
              <h1 className="text-center mt-4 font-bold ">
                $500 added to your wallet{" "}
              </h1>

              <h5 className="text-center mt-[4px] font-all text-[#738482]">
                Your funds have been added to{" "}
                <span className="font-semibold">INO</span> Wallet.
              </h5>
            </div>
            <div className="transcation-id">
              <h5 className="text-center mt-[62px] font-all text-[#738482]">
                Your Transcation ID is :<span className=""> 252034519023</span>{" "}
              </h5>
            </div>

            {/* end  */}
          </div>

          <div className="flex flex-col">
            <div className="img-content ">
              <img src={gif} alt="" className="image-coin w-[264px]" />
            </div>

            <div class="button-action relative top-[32px] flex flex-col items-center ">


              <button class=" bg-[#CB2229] text-white rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative">
                View receipt
              </button>
              <NavLink to="/wallet/addmoney" >   <button class="mb-6 border-[1px] border-[#CB2229] text-black rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative cursor-pointer  ">
                Go Back
              </button></NavLink>
            </div>
          </div>
        </div>
      </div> 
    </>
  )
}

export default Withdrawtransfersuccess
