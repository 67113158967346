import React from 'react'
import HomePcreatePost from './HomePcreatePost'
import HomepCommunityPost from './HomepCommunityPost'
import HomePsliderPost from './HomePsliderPost'
import HomePservicePost from './HomePservicePost'
import HomePjobPost from './HomePjobPost'
export default function HomePpostMain() {
  return (
    <>
<HomePcreatePost/>
<HomepCommunityPost/>
<HomePsliderPost/>
<HomePservicePost/>
<HomePjobPost/>
    </>
  )
}
