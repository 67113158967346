import React from 'react'
import PostCreatePost from './PostCreatePost'
import PostCommunityPost from './PostCommunityPost'
import PostProductSLider from './PostProductSLider'
import PostServicePost from './PostServicePost'
import PostJobPost from './PostJobPost'

export default function JobPostMain() {
  return (
    <>
   <PostCreatePost/>
   <PostCommunityPost/>
   <PostProductSLider/>
   <PostServicePost/>
   <PostJobPost/>
    </>
  )
}
