import React from 'react'
import Buttonstore from './Buttonstore'
import Buttonservice from './Buttonservice'
import Buttoncompany from './Buttoncompany'

function Buttonsmain() {
  return (
    <>
   <div className="flex flex-row gap-3 justify-center items-center">
    <Buttonstore/>
    <Buttonservice/>
    <Buttoncompany/>
    </div>   
    </>
  )
}

export default Buttonsmain
