import React from 'react'
import ExploreCreatePost from './ExploreCreatePost'
import ExploreCommunityPost from './ExploreCommunityPost'
import ExploreProductSlider from './ExploreProductSlider'
import ExploreService from './ExploreService'
import ExploreJob from './ExploreJob'

function ExplorePostMain() {
  return (
    <>
      <ExploreCreatePost/>
      <ExploreCommunityPost/>
      <ExploreProductSlider/>
      <ExploreService/>
      <ExploreJob/>
    </>
  )
}

export default ExplorePostMain
