import React from "react";
import AddCreatePost from "./AddCreatePost";
import AddCommunityPost from "./AddCommunityPost";
import AddProductSlider from "./AddProductSlider";
import AddService from "./AddService";
import AddPostJob from "./AddPostJob";

export default function AddProjectPost() {
  return (
    <>
      <AddCreatePost />
      <AddCommunityPost />
      <AddProductSlider />
      <AddService />
      <AddPostJob />
    </>
  );
}
