import React from "react";
import PallProductPart from "./PallProductPart";
import PourProductSlider from "./PourProductSlider";

export default function PallMain({ onClick }) {
  return (
    <>
      <div className="ml-4 mt-6">
        {/* product par is the top man product section of the card  */}
        <PallProductPart  onClick={onClick}  />
        <PourProductSlider />

        <div className="flex flex-row gap-3">
          <div className="a border-[#D2D2D2] border-[1px] rounded-md lg:w-[59rem] lg:mt-[2rem] mb-4">
            <h1 className="font-all font-semibold text-[#263238] ml-8 mt-4 text-[21px]">
              Frequently Asked Question
            </h1>

            <h3 className="font-all font-semibold ml-[13px] mt-[14px] text-[#141414] lg:text-[18px]">
              1. What is the nearest Landmark ?{" "}
            </h3>
            <p className="font-all ml-[28px] text-[#263238] mt-[8px] ">
              The establishment is 1 mile away from the xyz monument , city A.
            </p>

            <h3 className="font-all font-semibold ml-[13px] mt-4 text-[#141414] lg:text-[18px] ">
              2. What are the operational hours?
            </h3>
            <p className="font-all ml-[28px]">
              Monday <span>(12:am - 12:pm)</span>{" "}
            </p>
            <p className="font-all ml-[28px]">
              Tuesday <span>(12:am - 12:pm)</span>{" "}
            </p>
            <p className="font-all ml-[28px]">
              Wednesday <span>(12:am - 12:pm)</span>{" "}
            </p>
            <p className="font-all ml-[28px]">
              Thursday <span>(12:am - 12:pm)</span>{" "}
            </p>
            <p className="font-all ml-[28px]">
              Friday <span>(12:am - 12:pm)</span>{" "}
            </p>
            <p className="font-all ml-[28px] mb-3">
              Saturday <span>(12:am - 12:pm)</span>{" "}
            </p>
          </div>

          <div className="b mb-4 border-[1px] border-[#D2D2D2] lg:w-[15rem] rounded-md lg:mt-[31px]">
            <h2 class="mt-3 text-[#263238] font-semibold ml-3 text-[17px]">
              Report an Error
            </h2>
            <p className="font-all mt-4 p-[10px]">
              We aim to provide you with only the best experience. Incase you
              are finding any difficulty or problem, leave a message below to
              let us know!
            </p>

            <button class="mt-[15px] flex items-center gap-[10px] m-auto mx-auto justify-center bg-[#F8F8F8] shadow-md p-[6px] w-[11rem] rounded-lg transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white mb-3">
              Report now{" "}
              <span>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 16 16"
                  class="text-[20px]"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                  ></path>
                </svg>
              </span>{" "}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
