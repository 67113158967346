import React from 'react'

export default function PinputMain() {
  return (
    <>   <div className="form">
    <form action="">
      <div className="flex flex-row lg:gap-[9rem] mt-[3rem] mb-[2rem]">
        {/* 1 */}
        <div className="form-input flex flex-col  w-[47%]">
          <h1 className="font-all font-semibold text-center">
            Request Quotation
          </h1>
          <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
            <label
              htmlFor="input"
              className=" text text-sm  text-black w-[8rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
            >
              Product Categories
            </label>
            <input
              type="text"
              placeholder="Write here..."
              name="input"
              className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
              aria-autocomplete="list"
            />
          </div>{" "}
          {/* 2  */}
          <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
            <label
              htmlFor="input"
              className=" text text-sm  text-black w-[8rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
            >
              Product Rate
            </label>
            <input
              type="text"
              placeholder="Write here..."
              name="input"
              className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
              aria-autocomplete="list"
            />
          </div>{" "}
          {/* 3 */}
          <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
            <label
              htmlFor="input"
              className=" text text-sm  text-black w-[10rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
            >
              Minimum Order Quanity
            </label>
            <input
              type="text"
              placeholder="Write here..."
              name="input"
              className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
              aria-autocomplete="list"
            />
          </div>{" "}
          {/* 4  */}
          <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
            <label
              htmlFor="input"
              className=" text text-sm  text-black w-[5rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
            >
              MQ UNIT
            </label>
            <input
              type="text"
              placeholder="Write here..."
              name="input"
              className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
              aria-autocomplete="list"
            />
          </div>{" "}
          {/* 5  */}
          {/* text area  */}
          <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px] ml-[2.1rem]">
            <label
              htmlFor="input"
              className="text text-sm text-black w-[8rem] rounded-md bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
            >
              Detail Requirement
            </label>
            <textarea
              placeholder="Write here..."
              name="input"
              rows="7"
              className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
              aria-autocomplete="list"
            ></textarea>
          </div>
          <div className="add-attachment flex flex-row items-center gap-3 ml-[3rem] mt-4">
            <span className="font-all text-[35px]">+</span>{" "}
            <span className="font-all">add attachment</span>
          </div>
        </div>

        {/* 2 */}
        <div className="flex flex-col mt-[17rem]">
        
        <div class="flex items-center">
            <input
              id="link-checkbox"
              type="checkbox"
              value=""
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              for="link-checkbox"
              class="ml-2 text-sm font-all font-semibold text-gray-900 dark:text-gray-300"
            >
              I agree to share my Bussiness card with Supplier
              
              .
            </label>
          </div>

          <div class="flex items-center mt-3">
            <input
              id="link-checkbox"
              type="checkbox"
              value=""
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              for="link-checkbox"
              class="ml-2 text-sm font-all font-semibold text-gray-900 dark:text-gray-300"
            >
              I agree to follow buyer posting guideline
           
              .
            </label>
          </div>
        
          {/* buttin action */}

          <div class="button-action flex flex-col items-center mt-6">
            <button class=" bg-[#CB2229] text-white rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative">
              Share Inputs{" "}
            </button>
            <button class="mb-6 border-[1px] border-[#CB2229] text-black rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative  ">
              Create a Campaign
            </button>
          </div>
        </div>
        {/*  */}
      </div>
    </form>
  </div>
     

    </>
  )
}
