 import React from 'react'
import UserProfile from './UserProfile'

function Profilemain() {
  return (
    <>
     <UserProfile/>
    </>
  )
}

export default Profilemain
