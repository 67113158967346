// utils.js

import axios from "axios";
import Cookies from "js-cookie";

// Function to get a list of countries
export const getCountries = async () => {
  try {
    const response = await axios.get("https://restcountries.com/v3.1/all");
    return response.data;
  } catch (error) {
    throw new Error("Error fetching countries");
  }
};

// Function to register a phone number and get the token
export const registerPhoneNumber = async (selectedCountry, phoneNumber) => {
  try {
    const data = {
      country: selectedCountry,
      phone: phoneNumber,
    };

    const response = await axios.post(
      "https://inobackend-production.up.railway.app/api/v1/user/register",
      data
    );

    const token = response.data.token;

    // Save the token in cookies
    Cookies.set("authToken", token);

    return token;
  } catch (error) {
    throw new Error(error);
  }
};
