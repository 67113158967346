// this is the top main part of product part of slider product page
import React from "react";
import Product from "../../../../assets/images/product.png";
import { BsArrowRight,BsTelephone } from "react-icons/bs";
import {
  AiOutlineEye,
  AiOutlineQuestionCircle,
  AiOutlineHeart,
  AiOutlineShareAlt,
  AiOutlineMail
} from "react-icons/ai";
import { MdAdsClick } from "react-icons/md";
import { BsGraphUpArrow } from "react-icons/bs";
import { MdVerified } from "react-icons/md";
import { FaStar } from "react-icons/fa";
import { FaInstagram,FaTwitterSquare,FaYoutube  } from "react-icons/fa";
import { AiFillFacebook} from "react-icons/ai";

export default function PallProductPart({ onClick }) {
  return (
    <>
{/*  */}



    {/*  */}
      <div className="page-m-(a) ">
        <div className="m-item-1">
          <div className="flex flex-row">
            <div className="a item-1 img w-[48rem] ">
              <img
                src={Product}
                className="rounded-md shadow-md drop-c cursor-pointer "  onClick={onClick}
                alt=""
              />
            </div>
            <div className="b item-2 specs">
              <div className="flex flex-row gap-2 ml-2 mt-2">
                <div className="flex flex-col gap-2">
                  <span className="text-[12px] bg-[#dcdcdc] rounded-md p-[2px]  w-max h-max  ">
                    Fashion
                  </span>
                  <span className="text-[12px] bg-[#dcdcdc] rounded-md p-[2px]  w-max h-max  ">
                    Clothes
                  </span>
                  <span className="text-[12px] bg-[#dcdcdc] rounded-md p-[2px]  w-max h-max  ">
                    Seasonal
                  </span>
                </div>

                <div className="flex flex-col gap-2">
                  <span className="text-[12px] bg-[#dcdcdc] rounded-md p-[2px]  w-max h-max  ">
                    Shoes
                  </span>
                  <span className="text-[12px] bg-[#dcdcdc] rounded-md p-[2px]  w-max h-max  ">
                    Branded items
                  </span>
                  <span className=" text-[12px] bg-[#dcdcdc] rounded-md p-[2px]  w-max h-max  ">
                    Cosmetics
                  </span>
                </div>
              </div>
            </div>

            <div className="c item-2 location  border-[1px] border-[#D2D2D2] rounded-md shadow-md drop h-max w-[284px] ml-[9px]">
              <h1 className="font-all font-extrabold text-[#263238] ml-4 mt-6">
                Location
              </h1>
              <p className="font-all w-[202px] ml-4  mt-[12px]">
                123 Main Street Suite 500 Anytown, USA 12345
              </p>

              <button class="mt-[15px] flex items-center gap-[10px] m-auto mx-auto justify-center bg-[#F8F8F8] shadow-md p-[6px] w-[11rem] rounded-lg transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white mb-3">
                Get Direction{" "}
                <span>
                  <BsArrowRight className="text-[20px]" />
                </span>{" "}
              </button>
            </div>
          </div>
        </div>
        <div className="B-m-item-2 mt-6 flex flex-row gap-[3rem]">
          <div className="item-b-1">
            <div className="flex flex-row lg:gap-[16rem] lg:mt-[10px]">
              <h1 className="font-all font-extrabold text-[28px]">
                Store Full Name
              </h1>
              <div className="flex flex-row items-center gap-[13px]">
                <span className="h-max text-[15px] gap-[9px] text-[#263238] flex flex-row items-center bg-[#E98F92] p-[2px] rounded-md">
                  {" "}
              <span className="relative left-[3px]" >    Verified </span> <MdVerified className="text-[#263238]" />
                </span>

                <div className="flex items-center border-x-2 border-black px-3">
                  <FaStar className="w-[13px] h-5 text-yellow-300" />
                  <FaStar className="w-[13px] h-5 text-yellow-300" />
                  <FaStar className="w-[13px] h-5 text-yellow-300" />
                  <FaStar className="w-[13px] h-5 text-yellow-300" />
                  <FaStar className="w-[13px] h-5 text-gray-300 dark:text-gray-500" />
                  <p className="ml-2 text-sm font-medium text-[#263238] dark:text-gray-400">
                    4/5
                  </p>
                </div>

                <p className="font-bold font-all decoration-solid text-[#263238]">
                  Reviews
                </p>
              </div>
            </div>
          </div>
          <div className="item-b-2">  
            {/* <div className="flex flex-col gap-1 lg:mt-[-32px]">
              <div className="c-1 flex flex-row gap-[13px]">
                <span className="bg-[#E6E6E6] flex flex-row  gap-[6px] justify-center items-center  rounded-md p-[13px] w-[7rem] cursor-pointer transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
                  {" "}
                  <AiOutlineEye /> views
                </span>
                <span className="bg-[#E6E6E6] flex flex-row gap-[6px]  justify-center items-center  rounded-md p-[13px] w-[7rem] cursor-pointer transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
                  {" "}
                  <MdAdsClick /> clicks
                </span>
                <span className="bg-[#E6E6E6] flex flex-row gap-[6px]  justify-center items-center  rounded-md p-[13px] w-[7rem] cursor-pointer transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
                  {" "}
                  <AiOutlineHeart /> Likes
                </span>
              </div>
              <div className="c-2 flex flex-row gap-[13px]">
                <span className="bg-[#E6E6E6] flex flex-row gap-[6px]  justify-center items-center  rounded-md p-[13px] w-[7rem] cursor-pointer transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
                  {" "}
                  <AiOutlineQuestionCircle /> Inquiries
                </span>
                <span className="bg-[#E6E6E6] flex flex-row gap-[6px]  justify-center items-center  rounded-md p-[13px] w-[7rem] cursor-pointer transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
                  {" "}
                  <BsGraphUpArrow /> Ranking
                </span>
                <span className="bg-[#E6E6E6] flex flex-row gap-[6px]  justify-center items-center  rounded-md p-[13px] w-[7rem] cursor-pointer transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
                  {" "}
                  <AiOutlineShareAlt /> share
                </span>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="page-m-(b) mt-3 fade-in-left">
      <div className="flex flex-wrap border-[1px] border-[#D2D2D2] lg:p-[15px] rounded-md w-[99%]">
    <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8   border-opacity-60">
      <h2 className="text-lg sm:text-xl text-gray-900 font-semibold font-all title-font mb-2">
        Timing
      </h2>
      <p className="leading-relaxed text-base font-all ">
     12:00 am  to  12:00pm
      </p>
      <p className="leading-relaxed text-base font-all ">
     Monday to Friday
      </p>
   
    </div>
    <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8  border-l-2 border-gray-200 border-opacity-60">
      <h2 className="text-lg sm:text-xl text-gray-900 font-all font-semibold mb-2">
Year of Establishment 
      </h2>
      <p className="leading-relaxed text-base lg:text-[18px] ">
    20 <span className="italic font-all" > years</span>
      </p>
      <span className="font-all" >founded in 2003</span>
   
    </div>
    <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8  border-l-2 border-gray-200 border-opacity-60">
      <h2 className="text-lg sm:text-xl text-gray-900 font-all font-semibold mb-2">
        Contact us 
      </h2>
      <p className="leading-relaxed text-base flex flex-row items-center lg:gap-[8px] ">
    <BsTelephone/>  03211234467
      </p>
      <span className="flex flex-row items-center lg:gap-[8px]" >  <AiOutlineMail/>dummy@gmail.com</span>
   
    </div>
    <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8  border-l-2 border-gray-200 border-opacity-60">
      <h2 className="text-lg sm:text-xl text-gray-900 font-all font-semibold mb-2">
        Follow us
      </h2>
    <div className="flex flex-row-reverse items-center lg:gap-[7px] justify-center lg:mt-[13px]">
    <FaInstagram  className="lg:text-[33px] text-[#E1306C]" />
    <FaTwitterSquare  className="lg:text-[33px] text-[#1DA1F2] " />
    <FaYoutube  className="lg:text-[33px] text-[#FF0000]" />
    <AiFillFacebook  className="lg:text-[35px] rounded-md  text-[#445c8f]" />
    </div>
   
    </div>
  </div>
      </div>
    </>
  );
}
