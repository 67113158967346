import React from "react";
import Avatar from "./Avatar";

function Addmoney() {
  return (
    <>
      <div className="bg-layout drop-shadow-md lg:w-[152vh]  lg:ml-[17rem] relative top-[1rem] swing-in-top-fwd">
        <h1 className="font-bold text-[25px] ml-[2rem] relative top-[1rem]">Your Wallet</h1>

        <div className="flex flex-row lg:gap-[13rem] mt-[2rem] mb-[2rem]">
          <div className="flex flex-col ">
            <div className="border-[1px] border-[#D2D2D2] h-[104px] rounded-md w-[541px] ml-[2rem] mt-3">
              <div className="flex flex-col ml-[58px] mt-[1.3rem]">
                <h3 className="text-start font-all font-semibold inline ">
                  Current Balance
                </h3>
                <h3 className="text-start  font-bold text-[19px]  inline">
                  $333.5B
                </h3>
              </div>
            </div>

            <div className="form-input">
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-[6rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                >
                  Add Money
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="input"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                  aria-autocomplete="list"
                />
              </div>{" "}
            </div>

            <p className="font-all font-semibold  ml-[2.2rem] mt-3 text-[14px] mb-3">
              You can add upto 1000$
            </p>
            <div className="flex flex-row items-center gap-3 ml-[2.1rem]">
              <button className="bg-[#E6E6E6] rounded-full h-[33px] w-[173px] shadow-lg drop-shadow-md ">
                600$
              </button>
              <button className="bg-[#E6E6E6] rounded-full h-[33px] w-[173px] shadow-lg drop-shadow-md ">
                50$
              </button>
              <button className="bg-[#E6E6E6] rounded-full h-[33px] w-[173px] shadow-lg drop-shadow-md ">
                50$
              </button>
            </div>

            <p className="font-all font-semibold  ml-[2.2rem] mt-5 text-[14px]">
              Select Payment Method
            </p>

            <div className="flex flex-col gap-2 mt-[18px] ml-[32px] mb-4">
              <div class="flex flex-row gap-3">
                <div class="      lg:w-[173px] lg:h-[95px]    bg-[#E6E6E6]  border w-[60%] border-gray-300 rounded-md shadow-md">
                  <label class="flex items-center space-x-2 justify-center justify-center mt-[2rem]">
                    <input
                      type="radio"
                      name="radio"
                      class="form-radio bg-transparent text-[#738482]"
                    />
                    <span class="text-gray-700">Debit Card</span>
                  </label>
                </div>

                <div class="        lg:w-[173px] lg:h-[95px]    bg-[#E6E6E6] border w-[60%] border-gray-300 rounded-md shadow-md">
                  <label class="flex items-center space-x-2  justify-center mt-[2rem]">
                    <input
                      type="radio"
                      name="radio"
                      class="form-radio text-[#738482] bg-transparent"
                    />
                    <span class="text-gray-700">Credit card</span>
                  </label>
                </div>

                <div class="       lg:w-[173px] lg:h-[95px]    bg-[#E6E6E6]  border w-[60%] border-gray-300 rounded-md shadow-md">
                  <label class="flex items-center space-x-2 justify-center mt-[2rem]">
                    <input
                      type="radio"
                      name="radio"
                      class="form-radio text-[#738482] bg-transparent"
                    />
                    <span class="text-gray-700">Net Banking</span>
                  </label>
                </div>
              </div>
              <div class="flex flex-row gap-3">
                <div class="      lg:w-[173px] lg:h-[95px]    bg-[#E6E6E6]  border w-[60%] border-gray-300 rounded-md shadow-md">
                  <label class="flex items-center space-x-2 justify-center justify-center mt-[2rem]">
                    <input
                      type="radio"
                      name="radio"
                      class="form-radio bg-transparent text-[#738482]"
                    />
                    <span class="text-gray-700">Google pay</span>
                  </label>
                </div>

                <div class="        lg:w-[173px] lg:h-[95px]    bg-[#E6E6E6] border w-[60%] border-gray-300 rounded-md shadow-md">
                  <label class="flex items-center space-x-2 justify-center justify-center mt-[2rem]">
                    <input
                      type="radio"
                      name="radio"
                      class="form-radio text-[#738482] bg-transparent"
                    />
                    <span class="text-gray-700">UPI</span>
                  </label>
                </div>

                <div class="       lg:w-[173px] lg:h-[95px]    bg-[#E6E6E6]  border w-[60%] border-gray-300 rounded-md shadow-md">
                  <label class="flex items-center space-x-2 justify-center justify-center mt-[2rem]">
                    <input
                      type="radio"
                      name="radio"
                      class="form-radio text-[#738482] bg-transparent"
                    />
                    <span class="text-gray-700">Bank Transfer</span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <Avatar />
        </div>
      </div>
    </>
  );
}

export default Addmoney;
