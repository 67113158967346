import React from "react";
import SliderP from "./SliderP";
// import ProductHomeslider from "../../../ProductCardSlider/ProductHomeslider";
import Bestseller from "./Bestseller";
import { HiArrowLongLeft } from "react-icons/hi2";
import { NavLink } from "react-router-dom";
import ProductForYou from "./ProductForYou";
// NavLink

export default function Allmain() {
  return (
    <>
      <div className="main--div">
      <div
        className=" mt-2
     "
      >
        <div class="flex items-center justify-center h-48 mb-8 ">
          <SliderP />
        </div>

<div className="flex flex-row items-center">
<NavLink to="/"><div className=" lg:relative  lg:w-[100%]  lg:left-[1rem]    flex flex-row item-center justify-start gap-3 z-10 top-[11rem]"  style={{alignItems:"center"}}>
<span className='text-[32px] text-[#4b5563]'><HiArrowLongLeft/></span>
<h1 className="font-semibold  text-[#4b5563] text-[20px]">
  Go Back to home page 
</h1>
</div></NavLink>
</div>


        <div
          class="mt-[13rem] flex items-center justify-center h-48 rounded  lg:h-[24rem] "
          style={{ border: "1px solid #D2D2D2" }}
        >
          <div className="w-[140vh]">
            <Bestseller />
          </div>
        </div>

        <div
          class="flex items-center justify-center h-48 mt-10 rounded  lg:h-[24rem]"
          style={{ border: "1px solid #D2D2D2" }}
        >
          <div className="w-[140vh]">
          <ProductForYou/>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}
