import React from 'react'
import { CiCirclePlus } from "react-icons/ci";
import { BsPencilFill,BsArrowRight } from "react-icons/bs";

export default function PostServiceMain() {
  return (
    <>
        <div className="flex flex-row xs:flex-col justify-start lg:ml-6 gap-[3rem] mb-[16px] mt-[12px]">
        <div className="item-1">
          <div className="input-image">
            <div className="border-[#738482] rounded-md border-[1.1px] border-dashed lg:w-[42rem] lg:h-[9rem] lg:mb-2">
              <span className="lg:mt-4 flex justify-center lg:text-[57px] text-[#738482]">
                <CiCirclePlus />
              </span>
              <h3 className="text-center font-all ">Add Banner Photo</h3>
              <h6 className="text-center font-all text-[13px]">
                {" "}
                Accepted File Types : jpg,jpeg,png{" "}
              </h6>
            </div>
          </div>

          <div className="flex flex-row items-center justify-center">
            <h2 className="text-center font-bold lg:text-[18px]">Store Name</h2>
            <span className="flex flex-row items-center gap-1  lg:relative lg:left-[14rem] ">
              {" "}
              edit{" "}
              <span>
                <BsPencilFill className="text-[12px]" />
              </span>{" "}
            </span>
          </div>
          {/* action buttons  */}
          <div className="action-buttons flex flex-row lg:justify-center gap-6 lg:mt-5 lg:mb-3">
            <button className="bg-[#F8F8F8] shadow-md   p-[6px] w-[18rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
              Edit Catlouge
            </button>

            <button className="bg-[#F8F8F8] shadow-md   p-[6px] w-[18rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
              Add Offer
            </button>
          </div>
          {/* edit catrogries  */}
          <div className="edit-categories flex flex-col lg:gap-[20px] lg:mt-8 lg:mb-4">
            <div className="flex flex-row justify-center lg:gap-[21rem] border-b-[1px] ">
              <h3 className="font-extrabold font-all text-black">
                Add address
              </h3>
              <span>
                <BsPencilFill />
              </span>
            </div>
            {/* 2  */}
            <div className="flex flex-row justify-center lg:gap-[21rem] border-b-[1px]">
              <h3 className="font-extrabold font-all text-black">Add Timing</h3>
              <span>
                <BsPencilFill />
              </span>
            </div>
            {/* 3  */}
            <div className="flex flex-row justify-center lg:gap-[17rem] border-b-[1px]">
              <h3 className="font-extrabold font-all text-black">
                Add Phone number{" "}
              </h3>
              <span>
                <BsPencilFill />
              </span>
            </div>
            {/* 4  */}
            <div className="flex flex-row justify-center lg:gap-[20.4rem] border-b-[1px]">
              <h3 className="font-extrabold font-all text-black">
                Add Email ID{" "}
              </h3>
              <span>
                <BsPencilFill />
              </span>
            </div>
            {/* 5  */}
            <div className="flex flex-row justify-center lg:gap-[14rem] border-b-[1px]">
              <h3 className="font-extrabold font-all text-black">
                Add Year of Establishment{" "}
              </h3>
              <span>
                <BsPencilFill />
              </span>
            </div>
            {/* 6  */}
            <div className="flex flex-row justify-center lg:gap-[21rem] border-b-[1px]">
              <h3 className="font-extrabold font-all text-black">Add Awards</h3>
              <span>
                <BsPencilFill />
              </span>
            </div>

            {/* 7  */}
            <div className="flex flex-row justify-center lg:gap-[20rem] border-b-[1px]">
              <h3 className="font-extrabold font-all text-black">
                Add Certificate
              </h3>
              <span>
                <BsPencilFill />
              </span>
            </div>
          </div>
        </div>
        {/* item 2  */}
        <div className="item-2 shadow-md lg:p-[13px]">
          <div className="input-image">
            <div className="border-[#738482] rounded-md border-[1.1px] border-dashed lg:w-[24rem] mx-auto lg:h-[9rem] lg:mb-2">
              <span className="lg:mt-4 flex justify-center lg:text-[57px] text-[#738482]">
                <CiCirclePlus />
              </span>
              <h3 className="text-center font-all ">
                Add Product Photo{" "}
                <span className="text-xs text-black mt-3">upto 5</span>{" "}
              </h3>
              <h6 className="text-center font-all text-[10px]">
                {" "}
                Accepted File Types : jpg,jpeg,png{" "}
              </h6>
            </div>
          </div>

          <div className="action-buttons flex flex-row lg:justify-center gap-6 lg:mt-5 lg:mb-3">
            <button className="bg-[#F8F8F8] shadow-md   p-[6px] w-[13rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
              Upload Photos
            </button>

            <button className="bg-[#F8F8F8] shadow-md   p-[6px] w-[13rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
              Upload Videos
            </button>
          </div>

          <h5 className="text-start text-black font-all font-bold ml-3 lg:text-[14px] mt-6">
            Bussiness Websites
          </h5>
          <hr className="mt-3 "   />
          <div className="flex flex-row items-center lg:gap-4 justify-center mt-4">
            <img
              className="lg:h-[32px]"
              src="https://cdn-icons-png.flaticon.com/512/733/733547.png?w=740&t=st=1687188339~exp=1687188939~hmac=77dff7d319434235132fca73408190ec9906c6742eb9c534e62d6f84b9cc9331"
              alt=""
            />
            <img
              className="lg:h-[32px]"
              src="https://cdn-icons-png.flaticon.com/512/174/174855.png?w=740&t=st=1687188491~exp=1687189091~hmac=dcc4c4e1884b3295142e6a9beba0e7431af1476fc3e11906b93bfd6b2c36fdca"
              alt=""
            />
            <img
              className="lg:h-[32px]"
              src="https://cdn-icons-png.flaticon.com/512/733/733579.png?w=740&t=st=1687188565~exp=1687189165~hmac=187c453528f696a5aeadc511746ccaffbb5a01e39d1927e36b1f46b2d540ef8f"
              alt=""
            />
            <img
              className="lg:h-[33px]"
              src="https://cdn-icons-png.flaticon.com/512/174/174883.png?w=740&t=st=1687188618~exp=1687189218~hmac=cc2c3dd3ebe49d289e4bc9797e687b6994e9c136ac9b9c9a54d459b09234c79b"
              alt=""
            />

            <button className="bg-[#F8F8F8] shadow-md   p-[6px] w-[9rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
              Add Website
            </button>
          </div>
          <hr className="mt-3 "   />

<div className="add-cateogries-2 flex flex-col lg:gap-[5px]">

<div className="flex flex-row justify-center items-center lg:gap-[14.1rem]">
  <h5 className="font-all" >Manage compain</h5>
  <span className="text-[16px]" > <BsArrowRight/>  </span>
</div>

{/*  */}
<div className="flex flex-row justify-center items-center lg:gap-[21rem] border-t">
  <h5 className="font-all" >GST</h5>
  <span className="text-[16px]" > <BsArrowRight/>  </span>
</div>

{/*  */}
<div className="flex flex-row justify-center items-center lg:gap-[17rem] border-t">
  <h5 className="font-all" >Contact KYC</h5>
  <span className="text-[16px]" > <BsArrowRight/>  </span>
</div>
{/*  */}

<div className="flex flex-row justify-center items-center lg:gap-[16rem] border-t">
  <h5 className="font-all" >jd Pay Escrow </h5>
  <span className="text-[16px]" > <BsArrowRight/>  </span>
</div>
{/*  */}

<div className="flex flex-row justify-center items-center lg:gap-[17.5rem] border-t">
  <h5 className="font-all" >jd Pay KYC </h5>
  <span className="text-[16px]" > <BsArrowRight/>  </span>
</div>
<hr className="border mt-2" />
<p className="font-all fontsemi-bold ml-3" >other</p>
<div className="flex flex-row justify-center items-center lg:gap-[21rem] mt-3 border-t">
  <h5 className="font-all" >FAQ's</h5>
  <span className="text-[16px]" > <BsArrowRight/>  </span>
</div>

<div className="flex flex-row justify-center items-center lg:gap-[15rem] border-t">
  <h5 className="font-all" >Customer Support</h5>
  <span className="text-[16px]" > <BsArrowRight/>  </span>
</div>

<div className="flex flex-row justify-center items-center lg:gap-[16.3rem] border-t">
  <h5 className="font-all" >Report an Error</h5>
  <span className="text-[16px]" > <BsPencilFill/>  </span>
</div>

</div>

<button className="lg:mt-[21px] flex justify-center m-auto mx-auto shadow-md   p-[4px] w-[13rem] rounded-full transform hover:scale-105 duration-500 ease-in-out bg-[#CB2229] text-white ">
              Publish and preview
            </button>
        </div>
      </div>     
    </>
  )
}
