import React from "react";
import Logo from "../../assets/logo/Logo-Trans.png";
import image from "../../assets/logo/auth.png";
import { Link } from "react-router-dom";
import {FaUserEdit} from "react-icons/fa"
export default function Registration() {
  return (
    <>
      <div className="xs:min-h-screen">
        <div className=" lg:hidden  --logo flex justify-center xs:mt-[2rem]">
          <img
            src={Logo}
            alt=""
            className="lg:w-[134px] xs:h-[49px] bounce-in-top  "
          />
        </div>
        <div className="flex flex-row justify-start  ">
          <div className="xs:hidden item-1 h-screen lg:w-[100vh] bg-[#cb22290f]">
            <div className="flex flex-col justify-center items-center mt-12 gap-[12pxs]">
              <img
                src={Logo}
                alt=""
                className="lg:w-[134pxl.] bounce-in-top  "
              />
            </div>
            <div className="text">
              <h1 className="text-center font-bold text-[35px] leading-[70px] text-[#738482] mt-9">
                Welcome to INO
              </h1>
              <p className="text-center  text-[#738482] font-all font-semibold ">
                Register now to gain access to your account{" "}
              </p>
            </div>
            <div className="image relative top-[14px]">
              <img
                src={image}
                className="mx-ato m-auto h-[21rem] image-coin"
                alt=""
              />
            </div>

            <div className="flex flex-row items-center gap-4 justify-center mt-[7rem]">
              <p className="text-[14px]">License Policy</p>
              <p className="border-x p-[8px]  text-[14px] border-black">
                Privacy Policy
              </p>
              <p className="text-[14px]">Terms and Conition</p>
            </div>
            <p className="text-[#738482] text-[13px] text-center">
              Copyrights © 2023 All Rights Reserved by{" "}
              <span className="text-[#CB2229]">INO</span>{" "}
            </p>
          </div>
          <div className="item-2 bg-[#cb22290f] xs:bg-white w-[50%]  xs:w-[100%] flex ">
            <div className="fade-in-right bg-white xs:bg-[#cb22290f]  shdaow-md w-[88%] rounded-md  lg:mt-[9rem] xs:mt-8 xs:mx-auto h-[552px] drop-c ">
              <div className="flex flex-row justify-start gap-[4rem]   lg:ml-[40px] lg:mt-8 xs:justify-center xs:mx-auto">
                <div className="item-1 xs:hidden flex flex-col items-center">
                  {/* <div className="bg-black rounded-full p-[39px]">
                    <p className="text-white">.......</p>
                  </div> */}
                  <span className=""  ><FaUserEdit className="lg:text-[39px]" /></span>
                  <span className="  underline-offset-1 font-all text-gray-400">add profile photo</span>
                </div>
                <div className="item-2 ">
                  <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[23rem] ">
                    <label
                      htmlFor="input"
                      className=" text text-sm  text-black w-[71px] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                    >
                      Full Name
                    </label>
                    <input
                      type="text"
                      placeholder="Write here..."
                      name="input"
                      className="input p-[11px] text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                    />
                  </div>

                  <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[23rem] ">
                    <label
                      htmlFor="input"
                      className=" text text-sm  text-black w-[80px] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      placeholder="Write here..."
                      name="input"
                      className="input p-[11px] text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                    />
                  </div>

                  <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[23rem] ">
                    <label
                      htmlFor="input"
                      className=" text text-sm  text-black w-[80px] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                    >
                      Identification
                    </label>
                    <input
                      type="text"
                      placeholder="Write here..."
                      name="input"
                      className="input p-[11px] text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                    />
                  </div>

                  <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[23rem] ">
                    <label
                      htmlFor="input"
                      className=" text text-sm  text-black w-[107px] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                    >
                      About Yourself
                    </label>
                    <input
                      type="text"
                      placeholder="Write here..."
                      name="input"
                      className="input p-[11px] text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                    />
                  </div>

                  <div className=" xs:bg-white  cool-input-rounded text-center drop-c  rounded-full p-[9px] w-[70%] mx-auto mt-[2rem]">
                    use current location
                  </div>

                  <div className="mt-[8px] coolinput flex flex-col w-fit-content static max-w-240 lg:w-[23rem] ">
                    <label
                      htmlFor="input"
                      className=" text text-sm  text-black w-[107px] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                    >
                      Enter Location
                    </label>
                    <input
                      type="text"
                      placeholder="Write here..."
                      name="input"
                      className="input p-[11px] text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                    />
                  </div>

                  {/*  */}

                  <Link to="/auth/intrest">
                    {" "}
                    <button class="mt-[51px] flex justify-center items-center mx-auto m-auto bg-[#CB2229] text-white rounded-full font-all p-[6px]  w-[18rem]   transform hover:scale-105 duration-500 ease-in-out ">
                      Proceed
                    </button>
                    {/*  */}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
